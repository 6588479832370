<template>
  <div style="padding-left: 1.75rem">
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      {{ $route.name }}
      <div class="subHeadTitle">{{ $route.name }}</div>
      <div class="right" v-if="PERMISIONS.create">
        <div class="right" style="margin-top: 1.2rem">
          <div @click="createItem" class="Button1">
            <div class="icon">
              <img
                @load="imgLoaded"
                src="../assets/media/for_table/circle_ADD_new_etap.svg"
                alt=""
              />
            </div>
            <span class="Btn_content">
              Adaugă clasificație
            </span>
          </div>
        </div>
      </div>
    </div>
    <template v-if="PERMISIONS.list">
      <table-parent
        :reloadCount="reloadTable"
        :tableHead="tableHead"
        :prepareFn="prepareData"
        :apiModule="tableModule"
        :apiModuleName="'BUGETARE'"
        :tableMinimizeHead="true"
        :tableSpread="true"
        @Btn2="editItem"
        @Btn3="deleteItem"
        @totalRecords="x => (totalRecords = x)"
      />
    </template>
    <template v-else>
      <h3 style="display: flex; flex-direction: column; align-items: center">
        <span style="font-size: 4rem">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        Acces interzis
      </h3>
    </template>
  </div>
</template>

<script>
import PermisionsMixin from "@/mixins/bugetarePermMixin.js";
import updateRoleStatus from "../mixins/updateUserRoleStatus.js";
import { BUGETARE } from "../api.js";
import DialogModal from "@/components/DialogModal";
import TableParent from "@/components/TableParent";
const budgetType = [
  { label: "Economică", value: "economic" },
  { label: "Funcțională", value: "functional" },
];
export default {
  name: "Bugetare",
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },
  components: {
    TableParent,
  },
  mixins: [PermisionsMixin, updateRoleStatus],
  data() {
    return {
      tableHead: [
        {
          title: "Titlu",
          sort: true,
          queryKey: "titlu",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Denumire indicatori",
          sort: true,
          queryKey: "name",
          bottomHead: {
            quikFiltre: true,
          },
        },
        {
          title: "Tipul",
          queryKey: "type",
          bottomHead: {
            quikFiltre: "list",
            options: budgetType,
          },
        },
      ],
      tableModule: BUGETARE.get,
      totalRecords: 0,
      reloadTable: 0,
    };
  },
  methods: {
    reload() {
      this.reloadTable++;
    },
    prepareData(row) {
      const preparation = [
        row.titlu || "",
        /*   row.articol || '',
        row.alineat || '', */
        row.name || "",
        budgetType.find((el) => el.value == row.type).label || "",
      ];

      const x = this.PERMISIONS;
      const prepareBtn = [];

      if (x.edit === true) {
        prepareBtn.push(2);
      }
      if (x.delete === true) {
        prepareBtn.push(3);
      }

      if (prepareBtn.length) {
        preparation.push([prepareBtn]);
      }

      return preparation;
    },
    createItem() {
      this.$modal.show(
        DialogModal,
        {
          target: "AddClassification",
          title: "Adaugă clasificație",
          inputs: [
            {
              label: "Titlu",
              id: "titlu",
              errReason: "Introduceți titlul.",
            },
            {
              label: "Denumire indicatori",
              id: "name",
              errReason: "Introduceți denumirea indicatorilor.",
            },
            {
              label: "Tipul",
              id: "type",
              type: "select",
              selectData: budgetType,
              loading: false,
              errReason: "Selectați tipul indicatorilor.",
            },
          ],
          closeBtnText: "Anulează",
          button: {
            type: 2,
            value: "Adaugă",
            handler: (sendData) => {
              const error = (msg) => {
                this.$toastr.e(msg || "Adăugarea clasificației a eșuat.");
                this.reload();
                this.setLoad();
              };

              this.setSafeLoad(12000);
              BUGETARE.add({ ...sendData, type: sendData.type.value })
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Clasificația a fost adăugată.");
                    this.reload();
                    this.setLoad();
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "AddClassification",
          adaptive: true,
          width: "650",
          height: "800",
        }
      );
    },
    editItem(data) {
      if (!Number.isInteger(data.id)) return;

      this.$modal.show(
        DialogModal,
        {
          target: "EditClassification",
          title: "Modifică clasificație",
          inputs: [
            {
              label: "Titlu",
              id: "titlu",
              errReason: "Introduceți titlul.",
            },
            {
              label: "Denumire indicatori",
              id: "name",
              errReason: "Introduceți denumirea indicatorilor.",
            },
            {
              label: "Tipul",
              id: "type",
              type: "select",
              value: budgetType.find((el) => el.value == data.type),
              selectData: budgetType,
              loading: false,
              errReason: "Selectați tipul indicatorilor.",
            },
          ],
          closeBtnText: "Anulează",
          initialInputsData: data,
          button: {
            type: 2,
            value: "Modifică",
            handler: (sendData) => {
              const error = (msg) => {
                this.$toastr.e(msg || "Modificarea clasificației a eșuat.");
                this.reload();
                this.setLoad();
              };

              this.setSafeLoad(12000);
              BUGETARE.edit(data.id, this.getEdited({ ...sendData, type: sendData.type.value }, data, true))
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Clasificația a fost modificată.");
                    this.reload();
                    this.setLoad();
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "EditClassification",
          adaptive: true,
          width: "650",
          height: "800",
        }
      );
    },
    deleteItem(data) {
      if (!Number.isInteger(data?.id)) return;

      this.$modal.show(
        DialogModal,
        {
          target: "DeleteClassification",
          title: "Ștergere clasificație",
          content:
            '<span style="color:#f44336;display: block;margin-top: 2rem;"><i class="fas fa-exclamation-circle" style="font-size: 6rem;"></i> <p style="font-weight: 500;font-size: 2.2rem;">Ești sigur că vrei să ștergi clasificația bugetară selectată?</p></span>',
          closeBtnText: "Anulează",
          button: {
            type: 3,
            value: "Șterge",
            handler: () => {
              const error = (msg) => {
                this.$toastr.e(msg || "Ștergerea clasificației a eșuat.");
                this.reload();
                this.setLoad();
              };

              this.setSafeLoad(12000);
              BUGETARE.delete(data.id)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s("Clasificația a fost ștearsă.");
                    this.reload();
                    this.setLoad();
                  } else {
                    error();
                  }
                })
                .catch(error);
            },
          },
        },
        {
          name: "DeleteClassification",
          adaptive: true,
          width: "650",
          height: "400",
        }
      );
    },
  },
  created() {
    if (!this.PERMISIONS.list) {
      return;
    }

    const canViewButtonsColumn = ["edit", "delete"];

    this.updateRoleStatus();
    if (this.checkParamsInObj(this.PERMISIONS, canViewButtonsColumn)) {
      this.tableHead.push({
        spacer: true,
        minimize: 1,
        fixed: {
          right: true,
        },
        bottomHead: {
          quikFiltre: "clear",
        },
      });
    }
  },
};
</script>
